const convertToRecommendationBlock = (skuBases, skuVariants) => {
  const recommendationBases = skuBases.map((skuBase) => {
    return {
      skuBase: skuBase.skuBaseNumber,
      publicSkuBaseDetails: {
        displayTitle: skuBase.displayTitle,
        skuBaseNumber: skuBase.skuBaseNumber,
        productPageUrl: skuBase.productPageUrl,
        thumbnailImageLink: skuBase.thumbnailImageLink,
        rating: skuBase.rating,
        pricing: {
          salePrice: skuBase.pricing?.salePrice,
          salePriceString: skuBase.pricing?.salePriceString,
        },
        skuVariantSummaries: skuBase.skuVariantSummaries,
        segmentCategorization: skuBase.segmentCategorization,
        brand: skuBase.brand,
      },
    };
  });
  const recommendationVariants = skuVariants.map((skuVariant) => {
    const { skuBase } = skuVariant;
    return {
      skuVariant: skuVariant.skuVariantNumber,
      skuBase: skuBase.skuBaseNumber,
      publicSkuBaseDetails: {
        displayTitle: skuBase.displayTitle,
        skuBaseNumber: skuBase.skuBaseNumber,
        productPageUrl: skuBase.productPageUrl,
        thumbnailImageLink: skuBase.thumbnailImageLink,
        rating: skuBase.rating,
        pricing: {
          salePrice: skuBase.pricing?.salePrice,
          salePriceString: skuBase.pricing?.salePriceString,
        },
        skuVariantSummaries: skuBase.skuVariantSummaries,
        segmentCategorization: skuBase.segmentCategorization,
        brand: skuBase.brand,
      },
      publicSkuVariantDetails: {
        displayTitle: skuVariant.displayTitle,
        skuVariantNumber: skuVariant.skuVariantNumber,
        kit: skuVariant.kit,
      },
    };
  });
  const recommendations = [...recommendationBases, ...recommendationVariants];

  return { title: "Featured Products", recommendations };
};

export { convertToRecommendationBlock };
