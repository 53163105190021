import styles from "./UgcModalContent.module.scss";
import Image from "next/image";
import RecommendationsVertical from "Clutch/Containers/RecommendationsVertical";
import useFeaturedProducts from "../../hooks/useFeaturedProducts";
import React from "react";
import ReactPlayer from "react-player/lazy";
import useSegment from "Hooks/useSegment";

const UgcModalContent = ({
  imageUrl,
  title,
  userName,
  products,
  date,
  imageAltText,
  sourceUrl,
  contentType,
  thumbnailUrl,
  index
}) => {
  const { isLoading, featuredProducts } = useFeaturedProducts(products);
  const { sendCustomSegmentTrackEvent } = useSegment();

  const handleOnClickPreview = () => {

    const segmentEvent = createUgcVideoPlayedEvent(thumbnailUrl, sourceUrl);
    sendCustomSegmentTrackEvent(segmentEvent);
  };

  return (
    <div className={styles.container}>
      <div className={styles.mediaWrapper}>
        {contentType === "video" ? (
          <ReactPlayer
            light={thumbnailUrl}
            playing
            width="100%"
            height="100%"
            url={sourceUrl}
            controls={true}
            onClickPreview={handleOnClickPreview}
            className={styles.videoPlayer}
            onError={() => {
              console.error("error playing video.");
            }}
          />
        ) : (
          <Image
            style={{
              objectFit: "cover",
              borderTopLeftRadius: "0.5rem",
              borderBottomLeftRadius: "0.5rem",
            }}
            alt={decodeURI(imageAltText)}
            src={imageUrl}
            fill
          />
        )}
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.headingContainer}>
          <p className={"p p-1_25 p-bold"} data-testid={`ugc_modal_userName_${index}`}>{userName}</p>
        </div>
        <div className={styles.titleContainer}>
          <p className={"p p-0_875"}>{title}</p>
        </div>
        <RecommendationsVertical
          recBlock={featuredProducts}
          isLoading={isLoading}
          numberOfSkeletons={products?.length}
        />
      </div>
    </div>
  );
};

export default UgcModalContent;
