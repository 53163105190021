import PaginatedModal from "Clutch/Molecules/PaginatedModal/PaginatedModal";
import UgcModalContent from "./ugc-modal-content/UgcModalContent";

const UgcModal = ({
  id,
  isOpen,
  onClose,
  content,
  activeContentIndex,
  containerClassName,
}) => {
  const modalContent = content?.map((post, index) => (
    <UgcModalContent
      key={post.imageUrl}
      imageUrl={post.imageUrl}
      title={post.title}
      userName={post.userName}
      products={post.products}
      date={post.submittedAt}
      imageAltText={post.imageAltText}
      sourceUrl={post.sourceUrl}
      contentType={post.contentType}
      thumbnailUrl={post.thumbnailUrl}
      index={index}
    />
  ));

  return (
    <PaginatedModal
      id={id}
      isOpen={isOpen}
      modalContent={modalContent}
      activeIndex={activeContentIndex}
      containerClassName={containerClassName}
      onClose={onClose}
    />
  );
};

export default UgcModal;
