import Drawer from "Clutch/Molecules/Drawer";
import Panel from "Clutch/Atoms/Panel";
import classNames from "classnames";
import Image from "next/image";
import styles from "./UgcDrawer.module.scss";
import RecommendationsVertical from "Clutch/Containers/RecommendationsVertical";
import PaginationControl from "Clutch/Atoms/PaginationControl/PaginationControl";
import useFeaturedProducts from "../hooks/useFeaturedProducts";
import { useState, useEffect } from "react";
import ReactPlayer from "react-player/lazy";
import useSegment from "Hooks/useSegment";
import { createUgcVideoPlayedEvent } from "../../instrumentation/ugc/ugcSegmentEvents";

const UgcDrawer = ({
  className,
  isOpen,
  onClose,
  content,
  activeIndex,
  containerClassName,
}) => {
  const [contentIndex, setContentIndex] = useState(
    activeIndex <= content?.length - 1 ? activeIndex : 0
  );
  const post = content[contentIndex];
  const { isLoading, featuredProducts } = useFeaturedProducts(post?.products ?? []);
  const { sendCustomSegmentTrackEvent } = useSegment();

  useEffect(() => {
    if (activeIndex >= 0 && activeIndex <= content?.length - 1)
      setContentIndex(activeIndex);
  }, [activeIndex]);

  const handlePaginationLeft = () => {
    let newContentIndex = contentIndex - 1;
    if (newContentIndex < 0) newContentIndex = content?.length - 1;
    setContentIndex(newContentIndex);
  };

  const handlePaginationRight = () => {
    let newContentIndex = contentIndex + 1;
    if (newContentIndex == content?.length) newContentIndex = 0;
    setContentIndex(newContentIndex);
  };

  const handleOnClickPreview = () => {
    const segmentEvent = createUgcVideoPlayedEvent(thumbnailUrl, sourceUrl);
    sendCustomSegmentTrackEvent(segmentEvent);
  };

  return (
    <Drawer
      className={className}
      open={isOpen}
      closeFunction={onClose}
      width={450}
      location={"right"}
      containerClassName={containerClassName}
    >
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <p className={classNames("p p-1_25 p-bold", styles.hashtag)}>
            #builtwithspeedwaymotors
          </p>

          <div className={styles.image}>
            {post?.contentType === "video" ? (
              <ReactPlayer
                light={post?.thumbnailUrl}
                playing
                width="100%"
                height="100%"
                url={post?.sourceUrl}
                controls={true}
                className={styles.videoPlayer}
                onClickPreview={handleOnClickPreview}
                onError={() => {
                  console.error("error playing video.");
                }}
              />
            ) : (
              <Image
                style={{
                  objectFit: "cover",
                }}
                alt={decodeURI([post?.imageAltText])}
                src={post.imageUrl}
                fill
              />
            )}
            <PaginationControl
              className={styles.paginationLeft}
              direction={"left"}
              intent={"action"}
              onClick={handlePaginationLeft}
              hideOnMobile={false}
            />
            <PaginationControl
              className={styles.paginationRight}
              direction={"right"}
              intent={"action"}
              onClick={handlePaginationRight}
              hideOnMobile={false}
            />
          </div>
        </div>
        <Panel className={styles.bottomContainer} layer={0}>
          <div className={styles.heading}>
            <p
              className={classNames("p p-1 p-bold", styles.userName)}
              data-testid={`ugc_drawer_userName_${contentIndex}`}
            >
              {post.userName}
            </p>
          </div>
          <p className={classNames("p p-0_875", styles.title)}>{post?.title}</p>
          <RecommendationsVertical
            recBlock={featuredProducts}
            isLoading={isLoading}
            numberOfSkeletons={post?.products?.length}
            dataTestId={`ugc_featured_products`}
            blockIndex={0}
          />
        </Panel>
      </div>
    </Drawer>
  );
};

export default UgcDrawer;
